import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import '@rainbow-me/rainbowkit/styles.css';
import {
  connectorsForWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import {
  metaMaskWallet,
  trustWallet,
  okxWallet,
  bitgetWallet,
  coinbaseWallet,
  rainbowWallet,
  walletConnectWallet,
  injectedWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { bsc } from 'wagmi/chains'; // Menggunakan BSC Mainnet
import { publicProvider } from 'wagmi/providers/public';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';

// Mengonfigurasi RPC BSC alternatif
const { chains, publicClient } = configureChains(
  [bsc], // Menggunakan BSC Mainnet
  [
    // Tambahkan RPC BSC alternatif dengan prioritas
    jsonRpcProvider({
      rpc: (chain) => {
        if (chain.id === bsc.id) {
          return {
            http: 'https://bnb-mainnet.g.alchemy.com/v2/bbSQdu_8tGv4Ykcb0W9a1GEsJr3P_fYG', // RPC BSC utama dari Binance
            // Anda juga bisa menggunakan RPC BSC alternatif berikut:
            // http: 'https://bsc-dataseed1.binance.org',
            // http: 'https://bsc-dataseed2.binance.org',
            // http: 'https://bsc-dataseed3.binance.org',
            // http: 'https://bsc-dataseed4.binance.org',
            // http: 'https://bsc-dataseed.bnbchain.org',
            // http: 'https://rpc.ankr.com/bsc', // Ini adalah RPC Ankr yang sebelumnya bermasalah
            // http: 'https://binance.nodereal.io',
            // http: 'https://bsc-mainnet.public.blastapi.io',
            // http: 'https://bsc.publicnode.com',
          };
        }
        return null;
      },
    }),
    // Tetap gunakan public provider sebagai fallback
    publicProvider(),
  ]
);

// Dapatkan dari WalletConnect Cloud
const projectId = '106aa7f86a7212a1d290ea0a90594338'; 

// Daftar semua wallet yang ingin ditampilkan
const wallets = [
  {
    groupName: 'Popular',
    wallets: [
      metaMaskWallet({ projectId, chains }),
      trustWallet({ projectId, chains }),
      okxWallet({ projectId, chains }),
      bitgetWallet({ projectId, chains }),
      coinbaseWallet({ appName: 'Pi Network Airdrop', chains }),
      rainbowWallet({ projectId, chains }),
    ],
  },
  {
    groupName: 'Other Wallets',
    wallets: [
      walletConnectWallet({ projectId, chains }),
      injectedWallet({ chains }),
    ],
  },
];

const connectors = connectorsForWallets(wallets);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains} modalSize="compact">
        <App />
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>
);

reportWebVitals();