import React from 'react';

const Background = () => {
  return (
    <div className="background">
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern id="grid" width="100" height="100" patternUnits="userSpaceOnUse">
            <path d="M 100 0 L 0 0 0 100" fill="none" className="grid-line" />
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#grid)" />
      </svg>
      <img src="/dog-icon.png" alt="Dog mascot" className="dog-icon" />
    </div>
  );
};

export default Background;