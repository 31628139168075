import React from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import MorseLogo from './MorseLogo';

const EligibilityCheck = () => {
  return (
    <div className="eligibility-check">
      <div className="terminal-header">
        Morse Terminal
      </div>
      
      <div className="morse-logo-container">
        <MorseLogo />
      </div>
      
      <h1 className="code-style">
        Check Your<br />
        Eligibility
      </h1>
      
      <p className="subheading">
        Connect your wallet to find out the results of your research in Morse.
      </p>
      
      <div className="social-icons">
        <div className="social-icon">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="10" stroke="white" strokeWidth="2"/>
          </svg>
        </div>
        <div className="social-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 127.14 96.36"><path fill="#fff" d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z"/></svg>
        </div>
        <div className="social-icon">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 5L19 19M19 5L5 19" stroke="white" strokeWidth="2"/>
          </svg>
        </div>
        <div className="social-icon">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 4.01c-1 .49-1.98.689-3 .99-1.121-1.265-2.783-1.335-4.38-.737S11.977 6.323 12 8v1c-3.245.083-6.135-1.395-8-4 0 0-4.182 7.433 4 11-1.872 1.247-3.739 2.088-6 2 3.308 1.803 6.913 2.423 10.034 1.517 3.58-1.04 6.522-3.723 7.651-7.742a13.84 13.84 0 0 0 .497-3.753C20.18 7.773 21.692 5.25 22 4.009z" stroke="white" strokeWidth="1.5"/>
          </svg>
        </div>
        <div className="social-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512" fill="#fff">
            <path d="M255.7 17c-130.5 0-236.2 100.4-236.2 224.3 0 70.6 34.2 133.3 87.7 174.6l-22.3 66.9s-3.5 9.7 6.9 11.8c4.2.9 6.6-1.4 6.6-1.4l78.9-56.6c25 7.1 51.4 10.9 78.4 10.9 130.5.1 236.3-100.4 236.3-224.3C492 117.3 386.2 17 255.7 17z"/>
            <path d="M89.2 242.2c0-97.5 74.5-176.5 166.3-176.5 91.8 0 166.3 79 166.3 176.5s-74.5 176.5-166.3 176.5c-32 0-61.8-9.6-86.9-26.1l-48.9 35.2 13.9-41.9c-27-31.2-44.4-74.1-44.4-122.1z" fill="#151723"/>
            <path d="M173.5 203.6l-19.7-2.8c-2.4-.3-5.1 1.2-6.3 2.8l-13.2 16.1c-16.8-7.9-30.7-21.4-39.7-38.5l15-14.2c1.4-1.3 2.4-3 2.3-4.9l-1.8-19.8c-.5-5.5-5.5-9.4-11-8.6l-17.9 2.5c-5 .7-9 6.6-9 11.7 0 70.7 58.9 132.6 129.3 134.2 5.1.1 10.3-3.9 11.4-8.8l3.4-14.8c1.5-6.4-2.4-12.3-8.2-13.1z" fill="#fff"/>
          </svg>
        </div>
        <div className="social-icon">
          <svg fill="#fff" width="22" height="22" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <path d="M256 76c48.1 0 93.3 18.7 127.3 52.7S436 207.9 436 256s-18.7 93.3-52.7 127.3S304.1 436 256 436c-48.1 0-93.3-18.7-127.3-52.7S76 304.1 76 256s18.7-93.3 52.7-127.3S207.9 76 256 76m0-28C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48z"/>
          </svg>
        </div>
      </div>
      
      <ConnectButton.Custom>
        {({ account, chain, openAccountModal, openChainModal, openConnectModal, mounted }) => {
          return (
            <div className="wallet-connect-button-container">
              {(() => {
                if (!mounted || !account || !chain) {
                  return (
                    <button onClick={openConnectModal} className="connect-wallet-button">
                      Connect Wallet
                    </button>
                  );
                }
              })()}
            </div>
          );
        }}
      </ConnectButton.Custom>
    </div>
  );
};

export default EligibilityCheck;