import React from 'react';

const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <div className="logo-icon">𝑚</div>
        <div className="logo-text">Morse</div>
      </div>
      <div className="menu-icon">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="2" y="5" width="20" height="2" rx="1" fill="white"/>
          <rect x="2" y="11" width="20" height="2" rx="1" fill="white"/>
          <rect x="2" y="17" width="20" height="2" rx="1" fill="white"/>
        </svg>
      </div>
    </header>
  );
};

export default Header;