import React from 'react';

const MorseLogo = () => {
  return (
    <svg className="morse-logo" width="80" height="80" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <path d="M30 40C36.0751 40 41 34.8513 41 28.5C41 22.1487 36.0751 17 30 17C23.9249 17 19 22.1487 19 28.5C19 34.8513 23.9249 40 30 40Z" fill="url(#paint0_linear)"/>
        <path d="M50 60C56.0751 60 61 54.8513 61 48.5C61 42.1487 56.0751 37 50 37C43.9249 37 39 42.1487 39 48.5C39 54.8513 43.9249 60 50 60Z" fill="url(#paint1_linear)"/>
        <path d="M70 80C76.0751 80 81 74.8513 81 68.5C81 62.1487 76.0751 57 70 57C63.9249 57 59 62.1487 59 68.5C59 74.8513 63.9249 80 70 80Z" fill="url(#paint2_linear)"/>
      </g>
      <defs>
        <filter id="filter0_d" x="0" y="0" width="100" height="100" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="2"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear" x1="19" y1="17" x2="41" y2="40" gradientUnits="userSpaceOnUse">
          <stop stop-color="#C9C4FF"/>
          <stop offset="1" stop-color="#9D94FF"/>
        </linearGradient>
        <linearGradient id="paint1_linear" x1="39" y1="37" x2="61" y2="60" gradientUnits="userSpaceOnUse">
          <stop stop-color="#C9C4FF"/>
          <stop offset="1" stop-color="#9D94FF"/>
        </linearGradient>
        <linearGradient id="paint2_linear" x1="59" y1="57" x2="81" y2="80" gradientUnits="userSpaceOnUse">
          <stop stop-color="#C9C4FF"/>
          <stop offset="1" stop-color="#9D94FF"/>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MorseLogo;